import request from '@/utils/request';

export const storeExtensions = {
  actions: {
    uploadFile({ dispatch }, id) {
      return request.post(`/package-rate-manual/file/${id}`, {
        responseType: 'blob'
      });
    },
    async downloadFile({ dispatch }, id) {
      return await request.get(`/package-rate-manual/file/${id}`, {
        responseType: 'blob'
      });
    },
    deleteFile({ dispatch }, id) {
      return request.delete(`/package-rate-manual/file/${id}`);
    }
  }
};
