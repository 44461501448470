/*eslint no-unused-vars: "error"*/
import * as accesslogOptions from './options/accesslog';
import * as eventlogOptions from './options/eventlog';
import * as permissionOptions from './options/permission';
import * as optionOptions from './options/option';
import * as roleOptions from './options/role';
import * as userOptions from './options/user';
import * as packageRateManualOptions from './options/packageratemanual';

// PLOP: Import option files
// eslint-disable-next-line
import { RouteParent, CrudStore } from './interfaces';

const Security = {
  name: 'Security',
  srcPath: '@/pages/admin/security/',
  i18nTitlePrefix: 'menu_security_'
} as RouteParent;

const Application = {
  name: 'Application',
  srcPath: '@/pages/admin/application/',
  i18nTitlePrefix: 'menu_application_'
} as RouteParent;

const Company = {
  name: 'Company',
  srcPath: '@/pages/admin/company/',
  i18nTitlePrefix: 'menu_company_'
} as RouteParent;

const routeParentPages = {
  Security,
  Application,
  Company
};

export const routerPages = {};
Object.keys(routeParentPages).forEach(key => {
  routerPages[routeParentPages[key].name] = [];
});

const eventlog = {
  permissionName: 'Eventlog',
  requestUrl: '/eventlog',
  store: 'eventlog',
  backendEntityIDField: 'eventLogID',
  options: eventlogOptions
} as CrudStore;

const accesslog = {
  permissionName: 'Accesslog',
  requestUrl: '/accesslog',
  store: 'accesslog',
  backendEntityIDField: 'accessLogID',
  options: accesslogOptions
} as CrudStore;

const configuration = {
  permissionName: 'Configuration',
  requestUrl: '/configuration',
  store: 'configuration',
  backendEntityIDField: 'configurationID'
} as CrudStore;

const permission = {
  permissionName: 'Permission',
  requestUrl: '/permission',
  store: 'permission',
  backendEntityIDField: 'permissionID',
  options: permissionOptions
} as CrudStore;

const option = {
  permissionName: 'Option',
  requestUrl: '/option',
  store: 'option',
  backendEntityIDField: 'optionID',
  options: optionOptions
} as CrudStore;

const role = {
  permissionName: 'Role',
  requestUrl: '/role',
  store: 'role',
  backendEntityIDField: 'roleID',
  options: roleOptions
} as CrudStore;

const user = {
  permissionName: 'User',
  requestUrl: '/user',
  store: 'user',
  backendEntityIDField: 'userID',
  options: userOptions,
  route: {
    parent: Security,
    listViewName: 'Users'
  }
} as CrudStore;

const language = {
  permissionName: 'Language',
  requestUrl: '/language',
  store: 'language',
  backendEntityIDField: 'languageID'
} as CrudStore;

const country = {
  permissionName: 'Country',
  requestUrl: '/country',
  store: 'country',
  backendEntityIDField: 'countryID'
} as CrudStore;

const translation = {
  permissionName: 'Translation',
  requestUrl: '/string-description',
  store: 'translation',
  backendEntityIDField: 'stringDescriptionID'
} as CrudStore;

// PLOP: Crud store modules
const packageRateManual = {
  permissionName: 'PackageRateManual',
  requestUrl: '/package-rate-manual',
  store: 'packageRateManual',
  backendEntityIDField: 'packageRateManualID',
  options: packageRateManualOptions
} as CrudStore;

const entities = [
  packageRateManual,
  eventlog,
  accesslog,
  configuration,
  language,
  permission,
  option,
  role,
  user,
  country,
  translation
];

export default entities;
