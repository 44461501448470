import { WebStorageStateStore } from 'oidc-client-ts';

export const oidcSettings = {
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  authority: window.authority,
  client_id: window.clientId,
  redirect_uri: window.redirectUri,
  popup_redirect_uri: window.redirectUri,
  response_type: 'code',
  response_mode: 'query',
  automaticSilentRenew: true,
  silent_redirect_uri: window.redirectUri,
  scope: 'openid',
  post_logout_redirect_uri: window.redirectUri,
  loadUserInfo: false
};
