import isPlainObject from 'is-plain-object';

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    a: date.getDay(),
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    m: date.getMonth() + 1,
    s: date.getSeconds(),
    y: date.getFullYear()
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return timeStr;
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

// 格式化时间
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf('?') + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 *get getByteLen
 * @param {Sting} val input value
 * @returns {number} output value
 */
export function getByteLen(val) {
  let len = 0;
  for (let i = 0; i < val.length; i++) {
    // eslint-disable-next-line no-control-regex
    if (val[i].match(/[^\x00-\xff]/gi) != null) {
      len += 1;
    } else {
      len += 0.5;
    }
  }
  return Math.floor(len);
}

export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

export function param(json) {
  if (!json) {
    return '';
  }
  return cleanArray(
    Object.keys(json).map(key => {
      if (json[key] === undefined) {
        return '';
      }
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    })
  ).join('&');
}

export function param2Obj(url) {
  let id = null;
  const prettyUrlIds = url.match(/\/(\d+)/);
  if (prettyUrlIds) {
    id = prettyUrlIds[1];
  }
  let params = url.split('?')[1];
  if (id && params) {
    params = 'id=' + id + '&' + params;
  } else {
    if (id) {
      params = 'id=' + id;
    }
  }
  if (!params) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(params)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
}

export function trimAndRemoveLineBreak(html) {
  if (!html) {
    return html;
  }
  return html.replace(/^(<br>)+|(<br>)+$/, '');
}
export function stripHtmlKeepBr(html) {
  if (!html) {
    return html;
  }
  let _html = html.replace('<br>', '||br||');
  _html = _html.replace('<br />', '||br||');
  const stripped = stripHtml(_html);
  return trimAndRemoveLineBreak(stripped.replace('||br||', '<br>'));
}
export function stripHtml(html) {
  if (!html) {
    return html;
  }
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = html;
  const stripped = temporalDivElement.textContent || temporalDivElement.innerText || '';
  return stripped;
}
export function stripImg(html) {
  if (!html) {
    return html;
  }
  return trimAndRemoveLineBreak(html.replace(/<img[^>]*>/g, ''));
}
export function consolidateRichText(html) {
  if (!html) {
    return '';
  }
  return stripHtml(html.replace(/<\/p>/gi, ' '));
}
export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (typeof target !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach(property => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

export const pickerOptions = [
  {
    onClick(picker) {
      const end = new Date();
      const start = new Date(new Date().toDateString());
      end.setTime(start.getTime());
      picker.$emit('pick', [start, end]);
    },
    text: '今天'
  },
  {
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    },
    text: '最近一周'
  },
  {
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    },
    text: '最近一个月'
  },
  {
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    },
    text: '最近三个月'
  }
];

export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) {
          context = args = null;
        }
      }
    }
  };

  return function(...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) {
      timeout = setTimeout(later, wait);
    }
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    return source;
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
/**
 * Quick mono level comparison.
 * For deep comparison, use isEqual
 */
export function objectsAreEqual(obj1, obj2) {
  if (!obj1 || !obj2) {
    return false;
  }
  if (typeof obj1 !== typeof {} || typeof obj2 !== typeof {}) {
    return false;
  }
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  if (Object.keys(obj1).some(key => obj1[key] !== obj2[key])) {
    return false;
  }
  return true;
}
export function isEqual(obj1, obj2) {
  const isEqual = require('lodash.isequal');
  return isEqual(obj1, obj2);
}
export function arraysAreEqual(arr1, arr2) {
  if (!arr1 || !arr2) {
    throw new Error('arraysAreEqual: Arrays must be defined');
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  const notEqualFound = arr1.some((el, index) => el !== arr2[index]);
  return !notEqualFound;
}
export function arraysAreEqualIgnoreOrder(arr1, arr2) {
  if (!arr1 || !arr2) {
    throw new Error('arraysAreEqualIgnoreOrder: Arrays must be defined');
  }
  const _arr1 = [...arr1].sort();
  const _arr2 = [...arr2].sort();
  return arraysAreEqual(_arr1, _arr2);
}

export function parseSelector(selector, identifier) {
  let appendStr = '';
  if (!selector || Object.keys(selector).length !== 1) {
    throw new Error('Selector count is not equal to 1 ');
  }
  const key = Object.keys(selector)[0];
  if (!key) {
    throw new Error('Selector is invalid');
  }

  if (key !== 'id') {
    appendStr = 'By' + capitalize(Object.keys(selector)[0]);
  }
  identifier[key] = selector[key];

  return appendStr;
}

export function generateRandomString(maxLength) {
  if (maxLength === undefined) {
    maxLength = 26;
  }
  return (
    Math.random()
      .toString(36)
      .substring(2, 15) +
    Math.random()
      .toString(36)
      .substring(2, 15)
  ).substring(0, maxLength - 1);
}

export function focusFirstInputInEl(el) {
  const inputs = el.querySelectorAll('input:not([disabled])');
  if (inputs.length > 0) {
    inputs[0].focus();
  }
}
export function removeValueFromArray(arr, value) {
  if (!arr || !arr.length) {
    return;
  }
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
}

export function numberFormatDecimals(value, decimals) {
  const factor = Math.pow(10, decimals);
  return Math.round(factor * value) / factor;
}

export function countDecimals(num) {
  if (Math.floor(num.valueOf()) === num.valueOf()) {
    return 0;
  }
  return num.toString().split('.')[1].length || 0;
}

export function disableAutocompleteInEl(el) {
  /*
   * On default, element creates an 'input' element for el-date-picker
   * and gives it the attribute 'autocomplete=off'. This is however ignored
   * by Chrome, allowing its Autofill to fill the input with jibberish.
   * The workaround is to give the attribute autocomplete any random
   * string other than the accepted values 'on' or 'off'.
   *
   * See: https://gist.github.com/niksumeiko/360164708c3b326bd1c8
   */
  el.querySelectorAll('input').forEach(input => {
    input.setAttribute('autocomplete', 'ignore');
  });
}
export function array_concat() {
  const merged = [];
  for (let i = 0; i < arguments.length; i++) {
    if (Array.isArray(arguments[i])) {
      arguments[i].forEach(el => merged.push(el));
    }
  }
  return merged;
}

export const asyncForEach = async (array, callback) => {
  if (!Array.isArray(array)) {
    return;
  }
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export function hasValue(value) {
  return value !== null && value !== undefined;
}
export function isGiven(value) {
  return hasValue(value) && value !== '';
}

export function blobToBase64(blob, callback) {
  const reader = new FileReader();
  reader.onload = function() {
    const dataUrl = reader.result;
    const base64 = dataUrl.split(',')[1];
    callback(base64);
  };
  reader.readAsDataURL(blob);
}

export function saveBlobAs(data, name = 'file', type = 'text/plain') {
  const anchor = document.createElement('a');
  anchor.href = window.URL.createObjectURL(new Blob([data], { type }));
  anchor.download = name;
  anchor.click();
}

export function openPDFStream(fileStream) {
  return openFileStreamAs(fileStream, 'application/pdf');
}
function openFileStreamAs(data, type = 'application/pdf', target = '_blank') {
  const file = new Blob([data], { type });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL, target);
}

export function deepSortObjectByKey(unordered) {
  if (Array.isArray(unordered)) {
    return unordered.map(x => deepSortObjectByKey(x));
  }
  if (!isPlainObject(unordered)) {
    return unordered;
  }
  const ordered = {};
  Object.keys(unordered)
    .sort()
    .forEach(function(key) {
      ordered[key] = deepSortObjectByKey(unordered[key]);
    });
  return ordered;
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function tryGetValue(obj) {
  // Make copy of arguments to allow optimization of arguments
  // https://www.jstips.co/en/javascript/avoid-modifying-or-passing-arguments-into-other-functions-it-kills-optimization/
  const args = new Array(arguments.length);
  for (let i = 0; i < args.length; ++i) {
    args[i] = arguments[i];
  }

  const keys = args.slice(1);
  const value = keys.reduce((value, key) => value && value[key], obj);
  return value;
}

export function stringsAreEqual(str1, str2) {
  if (typeof str1 !== 'string' || typeof str2 !== 'string') {
    return false;
  }
  return str1.localeCompare(str2) === 0;
}

export function objectsAreEqualExact(obj1, obj2) {
  return stringsAreEqual(JSON.stringify(obj1), JSON.stringify(obj2));
}

export function getNumberAsPresentableString(value, decimals) {
  if (decimals === undefined) {
    decimals = 2;
  }
  if (!hasValue(value)) {
    return '';
  }
  return value.toFixed(decimals).replace('.', ',');
}

export function addClassNameToField(field, className) {
  const x = document.getElementsByName(field);
  let i;
  for (i = 0; i < x.length; i++) {
    x[i].className += ' ' + className;
  }
}

export function getFirstGivenValue(obj) {
  if (isGiven(obj)) {
    return obj;
  }
  const args = new Array(arguments.length);
  for (let i = 0; i < args.length; ++i) {
    args[i] = arguments[i];
  }

  if (args.length > 1) {
    return getFirstGivenValue.apply(this, args.slice(1));
  }
}

function escapeSpecialRegexChars(str) {
  const specialChars = ['[', ']', '(', ')', '{', '}', '-', '+', '/', '.', '*', '^', '\\'];
  const explodedStr = str.split('');
  let escapedStr = '';
  let char;
  while ((char = explodedStr.shift())) {
    escapedStr += specialChars.includes(char) ? '\\' + char : char;
  }
  return escapedStr;
}

export function boldAndEscapeHTMLString(str, find) {
  if (!str || !find) {
    return str;
  }
  const buildSearchStringRegExp = str => new RegExp(`(${escapeSpecialRegexChars(str)})`, 'gi');
  const intermediateBoldTagStart = 'BOLDTAGSTART';
  const intermediateBoldTagEnd = 'BOLDTAGEND';
  let result;
  try {
    result = str.replace(buildSearchStringRegExp(find), intermediateBoldTagStart + '$1' + intermediateBoldTagEnd);

    result = escapeForHTML(result);

    result = result.replace(buildSearchStringRegExp(intermediateBoldTagStart), '<b>');
    result = result.replace(buildSearchStringRegExp(intermediateBoldTagEnd), '</b>');
  } catch (err) {
    result = escapeForHTML(str);
  }
  return result;
}

export function escapeForHTML(rawStr) {
  if (!rawStr) {
    return rawStr;
  }
  const encodedStr = rawStr.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
    return '&#' + i.charCodeAt(0) + ';';
  });
  return encodedStr;
}

export function getFirstFocusableInEl(el) {
  if (!el || !el.querySelectorAll) {
    return undefined;
  }
  const focusable = el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
  return focusable[0];
}

export function wrapQuotes(input) {
  return `"${forceString(input)}"`;
}

export function isStringNumberWithinRange(src, lowerLimit, upperLimit) {
  const srcAsNumber = +src;
  if (Number.isNaN(srcAsNumber)) {
    return false;
  }
  return srcAsNumber >= lowerLimit && srcAsNumber <= upperLimit;
}

/**
 * src: https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0#gistcomment-2775538
 *
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 *
 * @param {string} s a string
 * @return {number} a hash code value for the given string.
 */
export function stringToHashCode(s) {
  let h;
  for (let i = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  }
  return h;
}

export function forceString(src) {
  return `${src || ''}`;
}

export function areStringInputsEqual(inputA, inputB) {
  return forceString(inputA) === forceString(inputB);
}

export function getFileExtension(fileName) {
  const re = /(?:\.([^.]+))?$/;
  return re.exec(fileName)[1];
}
