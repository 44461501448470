import {
  apiChangePassword,
  apiChangePasswordWithToken,
  apiForgotPassword,
  apiGetPasswordPolicy,
  apiLoginByUsername,
  apiLogout,
  apiValidateNewPasswordToken
} from '@/api/authentication';
import { removeToken, setToken, getToken } from '@/utils/auth';
import jwt_decode from 'jwt-decode';
import { getEpochTime } from '@/utils/date';

const token = getToken();
const userID = 0; // Don't fill because it is not stored in the token, it is retrieved when user/current is called

export default {
  actions: {
    changePasswordOfLoggedInUser({ commit, state }, passwordChangeInfo) {
      return new Promise((resolve, reject) => {
        apiChangePassword(state.userId, passwordChangeInfo.oldPassword, passwordChangeInfo.newPassword)
          .then(() => {
            commit('SET_PASSWORD_CHANGED', true);
            resolve();
          })
          .catch(error => {
            commit('SET_PASSWORD_CHANGED', false);
            reject(error);
          });
      });
    },
    frontEndLogout({ commit }) {
      return new Promise(resolve => {
        removeToken();
        resolve();
      });
    },

    getPasswordPolicy({ commit, state }) {
      return new Promise((resolve, reject) => {
        apiGetPasswordPolicy()
          .then(response => {
            //
            // Since mockjs does not support custom status codes, it can only be hacked like this
            if (!response.data) {
              reject(new Error('Failed to load password policy.'));
            }
            const data = {
              ...response.data,
              isFromDatabase: true
            };
            commit('SET_PASSWORD_POLICY', data);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    getUserInfo({ commit, state, dispatch }, params) {
      return new Promise((resolve, reject) => {
        if (!params) {
          params = [];
        }
        const withPermissions = params.withPermissions || true;
        dispatch('user/fetchUser', { id: state.userId, withPermissions }, { root: true })
          .then(response => {
            if (!response.data) {
              reject(new Error('Verification failed, please login again.'));
            }
            const data = response.data;
            if (withPermissions && data.roles && data.roles.length > 0) {
              // Verify that the returned roles are a non-null array
              commit('SET_ROLES', data.roles);
            } else {
              reject(new Error('getInfo: roles must be a non-null array!'));
            }
            commit('SET_USER_PERMISSIONS', data.permissions);
            commit('SET_USER_ID', data.userID);
            commit('SET_NAME', data.name);
            commit('SET_CODE', data.code);
            commit('SET_EMAIL', data.email);
            commit('SET_DESCRIPTION', data.description);
            commit('SET_AVATAR', data.avatar);
            commit('SET_DOMAIN_ID', data.domainID);
            commit('SET_REFERENCE', data.reference);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    forgotPassword({ commit }, userInfo) {
      return apiForgotPassword(userInfo.username, userInfo.email);
    },

    validateTokenForNewPassword({ commit }, token) {
      return apiValidateNewPasswordToken(token);
    },

    changePasswordWithToken({ commit }, tokenInfo) {
      return apiChangePasswordWithToken(tokenInfo.password, tokenInfo.token);
    },
    logOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        apiLogout(state.token)
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            removeToken();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    removeAuthTokens({ commit, state }) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
    },
    loginByUsername({ commit }, userInfo) {
      return new Promise((resolve, reject) =>
        apiLoginByUsername(userInfo.code, userInfo.password)
          .then(response => {
            const payload = response.data.split('.')[1];
            const jwtPayload = JSON.parse(atob(payload));
            const userId = parseInt(jwtPayload.sub);
            commit('SET_TOKEN', response.data);
            commit('SET_USER_ID', userId);
            setToken(response.data);
            resolve();
          })
          .catch(error => {
            reject(error);
          })
      );
    },

    persistUser({ dispatch }, userInfo) {
      return new Promise((resolve, reject) => {
        userInfo.domainID = this.state.authentication.domainID;
        dispatch('user/updateSelf', userInfo, { root: true })
          .then(() => {})
          .catch(error => {
            reject(error);
          });
      });
    },

    updateLoggedInUser({ commit, state, dispatch }, userInfo) {
      return new Promise((resolve, reject) => {
        const user = {
          ...userInfo,
          id: state.userId,
          domainID: state.domainID
        };
        dispatch('user/updateSelf', user, { root: true })
          .then(() => {
            commit('SET_NAME', userInfo.name);
            commit('SET_CODE', userInfo.code);
            commit('SET_EMAIL', userInfo.email);
            commit('SET_DESCRIPTION', userInfo.description);
            commit('SET_AVATAR', userInfo.avatar);
            commit('SET_REFERENCE', userInfo.reference);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    setToken({ commit }, token) {
      commit('SET_TOKEN', token);
      setToken(token);
    }
  },
  getters: {
    isTokenExpired: state => {
      const token = state.token;
      if (!token) {
        return true;
      }

      const jwt = jwt_decode(token);
      const expiresIn = jwt.exp - getEpochTime();
      return expiresIn <= 0;
    }
  },
  mutations: {
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_CODE: (state, code) => {
      state.code = code;
    },
    SET_RETAILERS: (state, retailers) => {
      state.retailers = retailers;
    },
    SET_DESCRIPTION: (state, description) => {
      state.description = description;
    },
    SET_DOMAIN_ID: (state, domainID) => {
      state.domainID = domainID;
    },
    SET_EMAIL: (state, email) => {
      state.email = email;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_PASSWORD_CHANGED: (state, passwordChanged) => {
      state.passwordChanged = passwordChanged;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_PASSWORD_POLICY: (state, passwordPolicy) => {
      state.passwordPolicy = passwordPolicy;
    },
    SET_REFERENCE: (state, reference) => {
      state.reference = reference;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting;
    },
    SET_STATUS: (state, status) => {
      state.status = status;
    },
    SET_USER_ID: (state, id) => {
      state.userId = id;
    },
    SET_USER_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    }
  },

  state: {
    avatar: '',
    code: '',
    description: '',
    domainID: '',
    email: '',
    name: '',
    passwordChanged: false,
    passwordPolicy: {
      minimumLength: 0,
      requireDigit: false,
      requireLowercase: false,
      isFromDatabase: false,
      requireUppercase: false,
      requireNonAlphanumeric: false
    },
    permissions: [],
    reference: '',
    roles: [],
    setting: {
      articlePlatform: []
    },
    status: '',
    user: '',
    userId: userID,
    token: token
  }
};
