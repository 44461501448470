import { RouteConfig } from 'vue-router';
import { PermissionLevel } from '@/utils/permission';

const LazyLayout = () => import('@/layout/index.vue');

export default [
  {
    children: [
      {
        component: () => import('@/pages/admin/security/roles/index.vue'),
        meta: {
          permissions: [{ Role: PermissionLevel.Read }],
          title: 'menu_admin_roles'
        },
        name: 'Roles',
        path: 'roles'
      },
      {
        component: () => import('@/pages/admin/security/roles/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ Role: PermissionLevel.Write }],
          title: 'createRole'
        },
        name: 'CreateRole',
        path: 'roles/create'
      },
      {
        component: () => import('@/pages/admin/security/roles/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Role: PermissionLevel.Read }],
          title: 'editRole'
        },
        name: 'EditRole',
        path: 'roles/edit/:id(\\d+)'
      },
      {
        component: () => import('@/pages/admin/security/users/index.vue'),
        meta: {
          permissions: [{ User: PermissionLevel.Read }],
          title: 'menu_admin_users'
        },
        name: 'Users',
        path: 'users'
      },
      {
        component: () => import('@/pages/admin/security/users/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ User: PermissionLevel.Write }],
          title: 'createUser'
        },
        name: 'CreateUser',
        path: 'users/create'
      },
      {
        component: () => import('@/pages/admin/security/users/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ User: PermissionLevel.Read }],
          title: 'editUser'
        },
        name: 'EditUser',
        path: 'users/edit/:id(\\d+)'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'peoples', title: 'menu_admin_security' },
    name: 'Security',
    path: '/security'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/admin/application/countries/index.vue'),
        meta: {
          permissions: [{ Country: PermissionLevel.Read }],
          title: 'menu_admin_countries'
        },
        name: 'Countries',
        path: 'countries'
      },
      {
        component: () => import('@/pages/admin/application/countries/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ Country: PermissionLevel.Write }],
          title: 'createCountry'
        },
        name: 'CreateCountry',
        path: 'countries/create'
      },
      {
        component: () => import('@/pages/admin/application/countries/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Country: PermissionLevel.Read }],
          title: 'editCountry'
        },
        name: 'EditCountry',
        path: 'countries/edit/:id'
      },
      {
        component: () => import('@/pages/admin/application/languages/index.vue'),
        meta: {
          permissions: [{ Language: PermissionLevel.Read }],
          title: 'menu_admin_languages'
        },
        name: 'Languages',
        path: 'languages'
      },
      {
        component: () => import('@/pages/admin/application/languages/create.vue'),
        hidden: true,
        meta: {
          icon: 'edit',
          permissions: [{ Language: PermissionLevel.Write }],
          title: 'createLanguage'
        },
        name: 'CreateLanguage',
        path: 'languages/create'
      },
      {
        component: () => import('@/pages/admin/application/languages/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Language: PermissionLevel.Read }],
          title: 'editLanguage'
        },
        name: 'EditLanguage',
        path: 'languages/edit/:id'
      },
      {
        component: () => import('@/pages/admin/application/translations/index.vue'),
        meta: {
          permissions: [{ Translation: PermissionLevel.Read }],
          title: 'menu_admin_translations'
        },
        name: 'Translations',
        path: 'translations'
      },
      {
        component: () => import('@/pages/admin/application/translations/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Translation: PermissionLevel.Read }],
          title: 'editTranslation'
        },
        name: 'EditTranslation',
        path: 'translations/edit/:id'
      },
      {
        component: () => import('@/pages/admin/application/configuration/index.vue'),
        meta: {
          permissions: [{ Configuration: PermissionLevel.Read }],
          title: 'menu_admin_configuration'
        },
        name: 'Configuration',
        path: 'configuration'
      },
      {
        component: () => import('@/pages/admin/application/configuration/edit.vue'),
        hidden: true,
        meta: {
          noCache: true,
          permissions: [{ Configuration: PermissionLevel.Read }],
          title: 'editConfiguration'
        },
        name: 'EditConfiguration',
        path: 'configuration/edit/:id'
      },
      {
        component: () => import('@/pages/admin/maintenance/accesslog/index.vue'),
        meta: {
          permissions: [{ AccessLog: PermissionLevel.Read }],
          title: 'menu_admin_accesslog'
        },
        name: 'Access log',
        path: 'accesslog'
      },
      {
        component: () => import('@/pages/admin/maintenance/eventlog/index.vue'),
        meta: {
          permissions: [{ EventLog: PermissionLevel.Read }],
          title: 'menu_admin_eventlog'
        },
        name: 'Event Log',
        path: 'eventlog'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'star', title: 'menu_admin_application' },
    name: 'Application',
    path: '/application'
  } as RouteConfig,
  {
    children: [
      // PLOP: Application routes
      {
        component: () => import('@/pages/admin/packageratemanuals/index.vue'),
        meta: {
          permissions: [{ PackageRateManual: PermissionLevel.Read }],
          title: 'menu_package_rate_manuals'
        },
        name: 'PackageRateManuals',
        path: 'package-rate-manuals'
      },
      {
        component: () => import('@/pages/admin/packageratemanuals/create.vue'),
        hidden: true,
        meta: {
          permissions: [{ PackageRateManual: PermissionLevel.Write }],
          title: 'createPackageRateManual'
        },
        name: 'CreatePackageRateManual',
        path: 'package-rate-manuals/create'
      },
      {
        component: () => import('@/pages/admin/packageratemanuals/edit.vue'),
        hidden: true,
        meta: {
          permissions: [{ PackageRateManual: PermissionLevel.Read }],
          title: 'editPackageRateManual'
        },
        name: 'EditPackageRateManual',
        path: 'packagerate-manuals/edit/:id(\\d+)'
      }
    ],
    component: LazyLayout,
    meta: { icon: 'tree', title: 'menu_admin_company_records' },
    name: 'Company',
    path: '/company'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/user/settings.vue'),
        meta: { noCache: true, title: 'userSettings' },
        name: 'Settings',
        path: 'settings'
      }
    ],
    component: LazyLayout,
    hidden: true,
    path: '/user',
    redirect: 'settings'
  } as RouteConfig
];
