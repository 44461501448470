import Vue from 'vue';
import Router, { Route, RouteConfig } from 'vue-router';
import ErrorRoutes from './errors';
import AppRoutes from './app';
import RouterPrefetch from 'vue-router-prefetch';

const LazyLayout = () => import('@/layout/index.vue');

Vue.use(Router);
Vue.use(RouterPrefetch);

export const routesWithPermissions = [...AppRoutes];

export const routesWithoutPermissions = [
  ...ErrorRoutes,
  {
    component: () => import('@/pages/login.vue'),
    hidden: true,
    name: 'login',
    path: '/login'
  } as RouteConfig,
  {
    component: () => import('@/pages/forgotPassword.vue'),
    hidden: true,
    name: 'forgot-password',
    path: '/forgot-password'
  } as RouteConfig,
  {
    component: () => import('@/pages/newPassword.vue'),
    hidden: true,
    name: 'new-password',
    path: '/new-password/:token',
    props: true
  } as RouteConfig,
  {
    component: () => import('@/pages/redirect.vue'),
    hidden: true,
    name: 'redirect',
    path: '/redirect/:path*'
  } as RouteConfig,
  {
    children: [
      {
        component: () => import('@/pages/home/index.vue'),
        meta: { icon: 'home', noCache: true, title: 'home' },
        name: 'home',
        path: 'home'
      }
    ],
    component: LazyLayout,
    path: '',
    redirect: 'home'
  } as RouteConfig,
  {
    hidden: true,
    path: '*',
    redirect: '/404'
  } as RouteConfig
];

export default new Router({
  routes: routesWithPermissions.concat(routesWithoutPermissions),
  scrollBehavior: (to: Route, from: Route, savedPosition: any | void) =>
    ({
      y: 0
    } as any)
});
