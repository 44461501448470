// Keys ougt to be sorted alphabetically, case sensitive.
// To autosort: repeat CTRL+S until all keys are sorted
//  OR
// repeat command until sorted: npm run lint-fix

/* eslint sort-keys-fix/sort-keys-fix: error */

//import '../../public/env.js';

export default {
  accesslog: {
    accessedAt: 'Accessed at',
    accessedBy: 'Accessed by',
    accessedResource: 'Accessed resource',
    ip: 'IP',
    parameters: 'Parameters'
  },
  autoUpdate: {
    alertConfirm: 'Reload page',
    alertMessage: 'There is a new update available for this application. Please reload the page to update.',
    alertTitle: 'Update'
  },
  common: {
    add: 'Add',
    address: 'Address',
    billingAddress: 'Billing address',
    block_notification_not_saved_warning: 'Notification setting not saved. Please try again later.',
    browserNotSupported: 'Your browser does not support this feature',
    cancel: 'Cancel',
    city: 'City',
    clearFilters: 'Clear all filters',
    close: 'Close window',
    close_unsaved_warning: 'You have unsaved changes. Are you sure you want to close this window?',
    close_warning: 'Are you sure you want to close this screen?',
    code: 'Code',
    companyAddress: 'Company address',
    confirm: 'Confirm',
    copy: 'Copy',
    copyToClipboard: 'Copy to clipboard',
    copyToClipboardFail: 'Copy to clipboard failed',
    copyToClipboardSuccess: 'Copied to clipboard',
    country: 'Country',
    data_created: 'The data has been saved',
    data_save_error: 'The data could not be be saved',
    data_updated: 'The changes are saved',
    date: 'Date',
    decimals: 'Decimals',
    delete: 'Delete',
    delete_cancelled: 'Delete cancelled',
    delete_completed: 'Delete completed',
    delete_record_summary_warning: 'Are you sure you want to delete <b>{summary}</b>?',
    delete_record_warning: 'Are you sure you want to delete this?',
    department: 'Department',
    description: 'Description',
    dontSave: "Don't save",
    dontSend: "Don't send",
    dont_show_again_warning: 'Never ask again',
    draft: 'Draft',
    ean: 'EAN-13',
    edit: 'Edit',
    email: 'Email address',
    emptyString: ' ',
    error: 'Error',
    export: 'Export',
    file: 'File',
    firstName: 'First name',
    gender: 'Gender',
    general: 'General',
    id: 'ID',
    inactive: 'Inactive',
    initials: 'Initials',
    jobFunction: 'Job function',
    lastName: 'Last name',
    leave_mail_warning: 'You are about to leave this page. Do you want to send the email before leaving?',
    leave_unsaved_warning: 'You are about to leave this page. Do you want to save changes',
    loading: 'Loading ...',
    mailingAddress: 'Mailing address',
    modified: 'Modified',
    name: 'Name',
    no: 'No',
    noData: 'No data',
    notApplicable: 'Not applicable',
    ok: 'OK',
    password: 'Password',
    postalCode: 'Postal code',
    prefix: 'Prefix',
    publish: 'Publish',
    quantity: 'Quantity',
    question: 'Question',
    readMore: 'Read more ...',
    reference: 'Reference',
    remark: 'Remark',
    remarks: 'Remarks',
    save: 'Save',
    saveClose: 'Save & close',
    saveNext: 'Save & next',
    search: 'Search',
    send: 'Send',
    skip: 'Skip',
    status: 'Status',
    stay: 'Stay on page',
    streetName: 'Street',
    streetNumber: 'Number',
    success: 'Success',
    switchOff: 'Off',
    switchOn: 'On',
    taskstatus: 'Task status',
    title: 'Title',
    type: 'Type',
    unit: 'Unit',
    username: 'Username',
    vatNumber: 'VAT number',
    warning: 'Warning',
    year: 'Year',
    yes: 'Yes'
  },
  configuration: {
    code: 'Code',
    fromDate: 'Valid from',
    value: 'Value'
  },
  editor: {
    entityFetchError: 'This item does not exist (anymore) or you do not have the permission to view this page.'
  },
  error: {
    general_request: 'Something went wrong. Try again later.',
    oneOrMoreInputs: 'Data could not be saved because there are errors in the form',
    request_401: 'You are not authorised',
    request_403: 'You do not have permission to perform this action',
    save: 'Something went wrong. Try again later.'
  },
  errorLog: {
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
    tips: 'Please click the bug icon in the upper right corner'
  },
  errorPages: {
    backToHome: 'Back to home',
    headline401: "You don't have permission to go to this page",
    headline403: "You don't have permission to go to this page",
    headline404: 'Page not found',
    message401: 'If you do not agree with this, please contact your administrator.',
    message403: 'If you do not agree with this, please contact your administrator.',
    message404: 'Please check that the URL you entered is correct.',
    return: 'return',
    title401: 'Oops',
    title403: 'Access denied',
    title404: 'Sorry'
  },
  eventlog: {
    exception: 'Exception',
    level: 'Level',
    logger: 'Logger',
    message: 'Message',
    occurredAt: 'Occurred at',
    thread: 'Thread'
  },
  fileUpload: {
    dragAndDropHtml: 'Drag and drop a PDF file to upload.',
    uploadTip:
      'Uploading a PDF file will make that file available for download. During download the name of the user will be added as a watermark.'
  },
  forgotPassword: {
    description:
      'You can change your password for security reasons or reset it if you forget it. Complete the form below to get the instructions sent to your email',
    done: 'An email has been sent to {email}. Please check your email for instructions.',
    submit: 'Send email',
    title: 'Forgot password'
  },
  home: {
    welcomeVisitor: 'Welcome visitor'
  },
  language: {
    code: 'ISO 639-1 Code'
  },
  login: {
    code: 'User name',
    forbidden: 'User/IP combination has been blocked.',
    logIn: 'Sign in',
    logInWithAnotherAccount: 'Sign in with another account',
    password: 'Password',
    title: 'Log in',
    unauthorised: 'Login information is wrong',
    username: 'Username'
  },
  mail: {
    bcc: 'Bcc',
    body: 'Body',
    cc: 'Cc',
    error: 'An error has occured. Your e-mail was not sent.',
    from: 'From',
    subject: 'Subject',
    success: 'Your e-mail has been sent successfully.',
    to: 'To'
  },
  navbar: {
    dashboard: 'Dashboard',
    language: 'Language',
    logOut: 'Log Out',
    screenfull: 'Screenfull',
    size: 'Global Size',
    theme: 'Theme'
  },
  newPassword: {
    description: 'You can now permanently change your password.',
    done: 'Your password has successfully been changed.',
    invalidToken: 'This link has expired.',
    submit: 'Change password',
    title: 'New password'
  },
  permission: {
    AccessLog: 'Access log',
    Configuration: 'Settings',
    Country: 'Countries',
    Domain: 'Domains',
    EventLog: 'Event log',
    Language: 'Languages',
    Option: 'Options',
    PackageRateManual: window.packageRateManual_EnvironmentType === 'PTM' ? 'Package rate manuals' : 'ITB manuals',
    Permission: 'Permissions',
    Role: 'Roles',
    Translation: 'Translations',
    User: 'Users',
    execute: 'Execute',
    none: 'None',
    pageIsReadonly: 'You are not allowed to edit this page.',
    read: 'Read',
    roles: 'Your roles',
    selectPlaceholder: 'Select the access level',
    switchRoles: 'Switch roles',
    tabPermissions: 'Permissions',
    tabTaskTypes: 'Task Types',
    write: 'Write'
  },
  pickrec: {
    chooseEntryFor: 'Choose "{caption}"',
    itemNotFound: "'{caption}' not found",
    multiSearchFiltersLabel: 'Filters',
    suggestionsMayBeMore: 'There are possibly more search hits',
    suggestionsRestCount: 'There are {count} more search hits'
  },
  request: {
    networkError: 'Network can not be reached.'
  },
  route: {
    Table: 'Table',
    avatarUpload: 'Avatar Upload',
    clipboardDemo: 'Clipboard',
    complexTable: 'Complex Table',
    componentIndex: 'Introduction',
    componentMixin: 'Mixin',
    components: 'Components',
    countTo: 'CountTo',
    createCountry: 'Create country',
    createLanguage: 'Create language',
    createPackageRateManual:
      window.packageRateManual_EnvironmentType === 'PTM' ? 'Create a package rate manual' : 'Create an ITB manual',
    createRole: 'Create a role',
    createUser: 'Create a user',
    customTreeTable: 'Custom TreeTable',
    dashboard: 'Dashboard',
    directivePermission: 'Directive Permission',
    documentation: 'Documentation',
    dragDialog: 'Drag Dialog',
    dragTable: 'Drag Table',
    dropzone: 'Dropzone',
    dynamicTable: 'Dynamic Table',
    editConfiguration: 'Edit setting',
    editCountry: 'Edit country',
    editLanguage: 'Edit language',
    editPackageRateManual:
      window.packageRateManual_EnvironmentType === 'PTM' ? 'Edit package rate manual' : 'Edit ITB manual',
    editRole: 'Edit role',
    editTranslation: 'Edit translation',
    editUser: 'Edit user',
    errorLog: 'Error Log',
    errorPages: 'Error Pages',
    example: 'Example',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    exportZip: 'Export Zip',
    externalLink: 'External Link',
    form: 'Form',
    guide: 'Guide',
    home: 'Home',
    i18n: 'I18n',
    icons: 'Icons',
    inlineEditTable: 'Inline Edit',
    introduction: 'Introduction',
    jsonEditor: 'JSON Editor',
    markdown: 'Markdown',
    menu_admin: 'Beheer',
    menu_admin_accesslog: 'Access Log',
    menu_admin_application: 'Application',
    menu_admin_company_records: 'Company records',
    menu_admin_configuration: 'Settings',
    menu_admin_countries: 'Countries',
    menu_admin_eventlog: 'Event Log',
    menu_admin_languages: 'Languages',
    menu_admin_options: 'Options lists',
    menu_admin_roles: 'Roles',
    menu_admin_security: 'Roles and users',
    menu_admin_translations: 'Translation',
    menu_admin_users: 'Users',
    menu_package_rate_manuals:
      window.packageRateManual_EnvironmentType === 'PTM' ? 'Package Rate Manuals' : 'ITB Manuals',
    page401: '401',
    page403: '403',
    page404: '404',
    pagePermission: 'Page Permission',
    permission: 'Permission',
    selectExcel: 'Export Selected',
    splitPane: 'SplitPane',
    sticky: 'Sticky',
    tab: 'Tab',
    theme: 'Theme',
    tinymce: 'Tinymce',
    treeTable: 'Tree Table',
    uploadExcel: 'Upload Excel',
    userSettings: 'User settings',
    zip: 'Zip'
  },
  size: {
    medium: 'Medium',
    mini: 'Mini',
    small: 'Small'
  },
  stringInsert: {
    btnText: 'Insert...',
    closeOnInsert: 'Sluit dialoog na invoegen',
    textIntro: 'Click on a text to insert.',
    textIntroUnavailable: 'There is nothing to insert.',
    textTitle: 'Insert text',
    toastInsert: 'Inserted: {name}'
  },
  table: {
    actions: 'Actions',
    searchPlaceholder: 'Type to search',
    searchSelectPlaceholder: 'Select to search'
  },
  tagsView: {
    close: 'Close',
    closeAll: 'Close All',
    closeOthers: 'Close Others',
    refresh: 'Refresh'
  },
  textArea: {
    placeholder: 'Your text'
  },
  translation: {
    code: 'Translation code'
  },
  user: {
    avatar: 'Profile picture',
    avatarInfo: 'URL to profile picture',
    newPassword: 'New password',
    newPasswordInfo: "Leave empty when you don't want to change your password",
    oldPassword: 'Current password',
    oldPasswordInfo: 'Enter your current password for verification',
    oldPasswordInvalid: 'You did not enter the correct current password',
    passwordChanged: 'Your password has been changed',
    repeatPassword: 'Repeat password',
    repeatPasswordInfo: 'Repeat your new password for verification',
    roles: 'Roles'
  },
  validation: {
    complexity: 'Choose complexity',
    ean: 'This barcode is incorrect',
    email: 'This is an invalid e-mail address',
    error: {
      alphanumeric: 'Password must contain a symbol',
      digit: 'Password must contain a digit',
      isFromDatabase: 'Could not validate your password, because the server could not be reached.',
      itemNotFound: 'Item not found.',
      length: 'Password must contain at least {count} characters',
      lowercase: 'Password must contain a lowercase character',
      passwordComplexity: 'The password does not meet the required complexity',
      uppercase: 'Password must contain an uppercase character'
    },
    exceedMax: 'This value is too high',
    formValidationFailed: 'This form has errors. Please review your input and retry',
    numeric: 'Only numbers allowed',
    oldPasswordRequired: 'Please input the old password',
    repeatPasswordMismatch: "Two inputs don't match",
    required: 'This field is required',
    unique: 'This value must be unique'
  },
  wizard: {
    next: 'Next',
    prev: 'Back',
    skip: 'Skip'
  }
};
